:root {
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-radius-radius-radius20: 20px;
  --dl-space-space-threeunits: 48px;
  --dl-color-default-textmute1: rgba(215, 215, 215, 1);
  --dl-color-default-textdefault: rgba(243, 243, 243, 1);
  --dl-color-default-textgreyout1: rgba(161, 161, 161, 1);
  --dl-color-dark_secondary-purple: #ba68c8ff;
  --dl-space-space-oneandhalfunits: 24px;
  --dl-color-default-backgrounddark: rgba(10, 10, 10, 1);
  --dl-color-dark_secondary-lightblue: rgba(128, 216, 255, 1);
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.image-contain {
  object-fit: contain;
  aspect-ratio: 9/6 auto;
  background-size: contain;
}
.background-contain {
  background-size: 300px 150px;
}
.content-divide-reverse {
  width: 100%;
  max-width: 1449px;
}
.content-divide-child {
  width: 50%;
  max-width: 50%;
}
.port-container-responsive {
  padding: 0 120px;
  max-width: 1449px;
  align-self: center;
}
.content-divide > section {
  width: 48%;
  max-width: 50%;
}
.content-divide-reverse >section {
  width: 48%;
  max-width: 50%;
}
.content-divide-reverse > img {
  width: 48%;
  max-width: 50%;
}
.content-divide > img {
  width: 48%;
  max-width: 50%;
}

.content-divide {
  width: 100%;
  max-width: 1449px;
}
.home-headline-align {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.Epilogue-24Light {
  font-size: 24px;
  font-family: Epilogue;
  font-weight: 300;
  line-height: 32px;
}
.H2 {
  font-size: 32px;
  font-style: normal;
  font-family: Epilogue;
  font-weight: 600px;
  font-stretch: normal;
}
.Epilogue-36Bold {
  font-size: 36px;
  font-family: Epilogue;
  font-weight: 700;
  line-height: 46px;
}
.Epilogue-24Bold1 {
  font-size: 24px;
  font-style: normal;
  font-family: Epilogue;
  font-weight: 700px;
  font-stretch: normal;
  letter-spacing: 0em;
}
.Epilogue-24Bold {
  font-size: 24px;
  font-family: Epilogue;
  font-weight: 700;
}
.Epilogue-17Regular {
  font-size: 17px;
  font-style: normal;
  font-family: Epilogue;
  font-weight: 400px;
  font-stretch: normal;
  letter-spacing: 0em;
}
.Epilogue-64Bold {
  font-size: 64px;
  font-family: Epilogue;
  font-weight: 700;
  line-height: 74px;
}
.Epilogue-36Light {
  font-size: 36px;
  font-style: normal;
  font-family: Epilogue;
  font-weight: 300px;
  line-height: 46px;
}
.Subtitle {
  font-size: 20px;
  font-style: normal;
  font-family: Epilogue;
  font-weight: 600px;
  font-stretch: normal;
}
.Epilogue-17 {
  font-size: 17px;
  font-style: normal;
  font-family: Epilogue;
  font-weight: 400px;
  line-height: 27px;
}
.Bodytext {
  font-size: 17px;
  font-style: normal;
  font-family: Epilogue;
  font-weight: 400px;
  font-stretch: normal;
}
.Epilogue-17Bold {
  font-size: 17px;
  font-family: Epilogue;
  font-weight: 700;
  line-height: 27px;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}


@media(max-width: 767px) {
  .content-divide-reverse > img {
    width: 100% !important;
    max-width: 100% !important;
  }
  .content-divide-child {
    width: 100% !important;
    max-width: 100% !important;
  }
  .port-container-responsive {
    padding: 0 60px;
  }
  .content-divide {
    display: flex;
    flex-direction: column;
  }
  .content-divide-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .overflown-small-size {
    overflow-y: hidden;
  }
}
@media(max-width: 479px) {
  .port-container-responsive {
    padding: 0 40px;
  }

  .home-headline-align {
    gap:10px;
    text-align: left;
    flex-direction: column-reverse;
  }

  .home-headline-align span {
    text-align: left !important;
  }
}
