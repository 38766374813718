.footer-container {
  width: 100%;
  height: 242px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  z-index:10;
}
.footer-contactinfo {
  gap: 60px;
  display: flex;
  padding: 100px;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-end;
  flex-shrink: 0;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.footer-footermessage {
  gap: 40px;
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-text {
  color: var(--dl-color-default-textmute1);
  width: 360px;
  height: auto;
  text-align: left;
  line-height: 42px;
}
.footer-socialicons {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-link {
  display: contents;
}
.footer-platformlinkedin {
  width: 27px;
  height: 27px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 3px;
  background-color: var(--dl-color-default-textdefault);
}
.footer-fill1 {
  top: 5.0625px;
  left: 5.0625px;
  width: 15px;
  height: 15px;
  position: absolute;
}
.footer-link1 {
  display: contents;
}
.footer-fimail {
  width: 27px;
  height: 27px;
  text-decoration: none;
}






@media(max-width: 767px) {
  .footer-contactinfo {
    align-content: center;
  }
  .footer-text {
    width: 100%;
    text-align: center;
  }
}
@media(max-width: 479px) {
  .footer-container {
    height: auto;
  }
  .footer-contactinfo {
    flex-wrap: wrap;
  }
}
