.portfolio-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.portfolio-portfolio {
  height: auto;
  display: flex;
  z-index: 8;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-default-backgrounddark);
}
.portfolio-header {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  min-height: 80vh;
  align-items: center;
  flex-shrink: 0;
  padding-top: 0px;
  padding-left: 60px 0;
  padding-right: 60px 0;
  flex-direction: column;
  padding-bottom: 0px;
}
.portfolio-header1 {
  gap: 28px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  min-height: 70vh;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-start;
}
.portfolio-video {
  top: 0px;
  left: 0px;
  right: 0px;
  width:  100%;
  height: auto;
  opacity: 0.95;
  z-index: -1;
  position: absolute;
  padding-bottom: 0px;
}
.portfolio-headline-subhead {
  gap: 40px;
  width: auto;
  display: flex;
  z-index: 100;
  flex-grow: 1;
  max-width: 70%;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 40px;
  flex-direction: column;
  padding-bottom: 40px;
}
.portfolio-headline-subhead01 {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-text {
  color: var(--dl-color-default-textdefault);
  width: auto;
  height: auto;
  font-size: 64px;
  font-style: SemiBold;
  text-align: left;
  font-family: "Epilogue";
  line-height: 74px;
  font-stretch: normal;
  text-decoration: none;
}
.portfolio-text01 {
  font-style: normal;
  font-weight: 300;
}
.portfolio-text03 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Epilogue";
  line-height: 32px;
  letter-spacing: 0em;
}
.portfolio-portfolio1 {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 0 120px;
  padding-left: auto;
  padding-right: auto;
  flex-direction: column;
  padding-bottom: 0 120px;
}
.portfolio-latestwork-section {
  gap: 60px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
  padding-bottom: 40px;
}
.portfolio-text10 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  text-align: center;
  line-height: 46px;
}
.portfolio-workcards {
  gap: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolio-navlink {
  display: contents;
}
.portfolio-workcard {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-workcard-locked-aspect-ratio {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-workcard01 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-workcard-locked-aspect-ratio1 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-workcard02 {
  gap: 36px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-headline-subhead02 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-frame2 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.portfolio-text12 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  flex-grow: 1;
  font-size: 36px;
  text-align: left;
  font-family: "Epilogue";
  font-weight: 700;
  line-height: 46px;
  font-stretch: normal;
}
.portfolio-text16 {
  color: var(--dl-color-default-textgreyout1);
  font-size: 17px;
  font-style: normal;
  line-height: 27px;
}
.portfolio-text17 {
  fill: var(--dl-color-dark_secondary-purple);
  color: var(--dl-color-dark_secondary-purple);
  width: 250px;
  height: auto;
  font-style: normal;
  text-align: right;
  line-height: 32px;
  letter-spacing: 0em;
}
.portfolio-text18 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  align-self: stretch;
  text-align: left;
  font-stretch: normal;
  text-decoration: none;
}
.portfolio-homecontentimage-locked-aspect-ratio {
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.portfolio-image {
  width: 90%;
  height: auto;
  align-self: flex-end;
  max-height: 700px;
  object-fit: cover;
}
.portfolio-image1 {
  top: 6%;
  left: 0%;
  width: 27%;
  position: absolute;
  object-fit: cover;
}
.portfolio-frame {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-frame01 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-workcard03 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-workcard-locked-aspect-ratio2 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-workcard04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-workcard-locked-aspect-ratio3 {
  width: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-navlink1 {
  display: contents;
}
.portfolio-workcard05 {
  gap: 36px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-headline-subhead03 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-frame21 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.portfolio-text19 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  flex-grow: 1;
  font-size: 36px;
  text-align: left;
  font-family: "Epilogue";
  font-weight: 700;
  line-height: 46px;
  font-stretch: normal;
}
.portfolio-text22 {
  color: var(--dl-color-default-textgreyout1);
  font-size: 17px;
  font-style: normal;
  line-height: 27px;
}
.portfolio-text23 {
  fill: var(--dl-color-dark_secondary-purple);
  color: var(--dl-color-dark_secondary-purple);
  width: 250px;
  height: auto;
  font-style: normal;
  text-align: right;
  line-height: 32px;
  letter-spacing: 0em;
}
.portfolio-text24 {
  color: var(--dl-color-default-textmute1);
  width: 100%;
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 27px;
}
.portfolio-homecontentimage-locked-aspect-ratio01 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  max-height: 700px;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage01 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-size: contain;
  background-image: url("/external/tq_bbiihmrvsu-6xr.png");
  background-repeat: no-repeat;
  background-position: center;
}
.portfolio-homecontentimage-locked-aspect-ratio02 {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage02 {
  width: auto;
  height: 793.5411376953125px;
  display: flex;
  max-width: 100%;
  align-self: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-frame02 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-frame03 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-frame04 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-frame05 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-workcard06 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-workcard-locked-aspect-ratio4 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-navlink2 {
  display: contents;
}
.portfolio-workcard07 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-workcard-locked-aspect-ratio5 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-workcard08 {
  gap: 36px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-headline-subhead04 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-frame22 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.portfolio-text26 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  flex-grow: 1;
  font-size: 36px;
  font-style: normal;
  text-align: left;
  font-family: "Epilogue";
  font-weight: 700;
  line-height: 46px;
  font-stretch: normal;
}
.portfolio-text28 {
  color: var(--dl-color-default-textgreyout1);
  font-size: 17px;
  line-height: 27px;
}
.portfolio-text29 {
  fill: var(--dl-color-dark_secondary-purple);
  color: var(--dl-color-dark_secondary-purple);
  width: 250px;
  height: auto;
  font-style: normal;
  text-align: right;
  line-height: 32px;
  letter-spacing: 0em;
}
.portfolio-text30 {
  color: var(--dl-color-default-textmute1);
  width: auto;
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 27px;
}
.portfolio-homecontentimage-locked-aspect-ratio03 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage03 {
  width: 100%;
  height: 50vw;
  display: flex;
  position: relative;
  align-self: stretch;
  max-height: 700px;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-homecontentimage04 {
  top: 0px;
  left: -0.0283203125px;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/tq_3wizyydqij-35t.png");
  background-repeat: no-repeat;
  background-position: center;
}
.portfolio-vsethomegif1 {
  top: 13.5%;
  left: 10.7%;
  width: 39%;
  height: 35%;
  position: absolute;
}
.portfolio-frame06 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-frame07 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-frame08 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-latestwork-section-property3 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 140px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 140px;
}
.portfolio-text32 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  text-align: center;
  line-height: 46px;
}
.portfolio-workcards1 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 80px;
  justify-content: center;
}
.portfolio-link {
  display: contents;
}
.portfolio-workcard09 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-homecontentimage05 {
  width: 356px;
  height: 237px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-homecontentimage-locked-aspect-ratio04 {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage06 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/tq_0z2awtarxm-xfm9.png");
  background-repeat: no-repeat;
  background-position: center;
}
.portfolio-frame09 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-headline-subhead05 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolio-text34 {
  color: var(--dl-color-default-textmute1);
  width: 315px;
  height: auto;
  font-style: Bold;
  text-align: center;
  line-height: 27px;
  font-stretch: normal;
}
.portfolio-text35 {
  color: var(--dl-color-default-textgreyout1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolio-link1 {
  display: contents;
}
.portfolio-workcard10 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-homecontentimage07 {
  width: 356px;
  height: 237px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-homecontentimage-locked-aspect-ratio05 {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage08 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/tq_hl6l1fswru-6y6r.png");
  background-repeat: no-repeat;
  background-position: center;
}
.portfolio-frame10 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-headline-subhead06 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolio-text37 {
  color: var(--dl-color-default-textmute1);
  width: 315px;
  height: auto;
  font-style: Bold;
  text-align: center;
  line-height: 32px;
  font-stretch: normal;
}
.portfolio-text39 {
  color: var(--dl-color-default-textgreyout1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolio-link2 {
  display: contents;
}
.portfolio-workcard11 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-homecontentimage09 {
  width: 356px;
  height: 237px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-homecontentimage-locked-aspect-ratio06 {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage10 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/case-study-onboarding-1500h.png");
  background-repeat: no-repeat;
  background-position: center;
}

.portfolio-homecontentimage20 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/controller-cover.png");
  background-repeat: no-repeat;
  background-position: center;
}

.portfolio-homecontentimage13 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/vprod-cover.png");
  background-repeat: no-repeat;
  background-position: center;
}

.portfolio-frame11 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-headline-subhead07 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolio-text41 {
  color: var(--dl-color-default-textmute1);
  width: 315px;
  height: auto;
  font-style: Bold;
  text-align: center;
  line-height: 32px;
  font-stretch: normal;
}
.portfolio-text42 {
  color: var(--dl-color-default-textgreyout1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolio-text43 {
  fill: var(--dl-color-default-textmute1);
  color: var(--dl-color-default-textmute1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 46px;
  padding-top: 40px;
}
.portfolio-workcards2 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 80px;
  justify-content: center;
}
.portfolio-link3 {
  display: contents;
}
.portfolio-workcard12 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-homecontentimage11 {
  width: 356px;
  height: 237px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-homecontentimage-locked-aspect-ratio07 {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage12 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/tq_2tdiqvk9gd-mb72.png");
  background-repeat: no-repeat;
  background-position: center;
}
.portfolio-frame12 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-headline-subhead08 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolio-text45 {
  color: var(--dl-color-default-textmute1);
  width: 315px;
  height: auto;
  font-style: Bold;
  text-align: center;
  line-height: 32px;
  font-stretch: normal;
}
.portfolio-text47 {
  color: var(--dl-color-default-textgreyout1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolio-link4 {
  display: contents;
}
.portfolio-workcard13 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}

.portfolio-workcard20 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}

.portfolio-homecontentimage13 {
  width: 356px;
  height: 237px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-homecontentimage-locked-aspect-ratio08 {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage14 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/joist-design-system.png");
  background-repeat: no-repeat;
  background-position: center;
}
.portfolio-frame13 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-headline-subhead09 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolio-text49 {
  color: var(--dl-color-default-textmute1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 32px;
}
.portfolio-text50 {
  color: var(--dl-color-default-textgreyout1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolio-link5 {
  display: contents;
}
.portfolio-workcard14 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-homecontentimage15 {
  width: 356px;
  height: 237px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-homecontentimage-locked-aspect-ratio09 {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage16 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/tq_f9quwlzhc5-4k85u.png");
  background-repeat: no-repeat;
}
.portfolio-frame14 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-headline-subhead10 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolio-text51 {
  color: var(--dl-color-default-textmute1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 32px;
}
.portfolio-text52 {
  color: var(--dl-color-default-textgreyout1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolio-link6 {
  display: contents;
}
.portfolio-workcard15 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.portfolio-homecontentimage17 {
  width: 356px;
  height: 237px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolio-homecontentimage-locked-aspect-ratio10 {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-homecontentimage18 {
  width: 355.9715576171875px;
  height: 236.5411376953125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: contain;
  background-image: url("/external/tq_pmhhzg5_4t-mzsf.png");
  background-repeat: no-repeat;
  background-position: center;
}
.portfolio-frame15 {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolio-headline-subhead11 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolio-text54 {
  color: var(--dl-color-default-textmute1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 32px;
}
.portfolio-text55 {
  color: var(--dl-color-default-textgreyout1);
  width: 315px;
  height: auto;
  text-align: center;
  line-height: 27px;
}

@media(max-width: 991px) {
  .portfolio-video {
    width: auto;
    height: 100%;
    padding-bottom: 0px;
  }
  .portfolio-text10 {
    animation-name: none;
    animation-delay: 0s;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .portfolio-headline-subhead04 {
    animation-name: fadeInUp;
    animation-delay: 0s;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .portfolio-workcards1 {
    gap: 60px;
  }
}
@media(max-width: 767px) {
  .portfolio-portfolio {
    overflow-y: hidden;
  }
  .portfolio-video {
    top: 0px;
    left: -30%;
    right: 0px;
    opacity: 0.6;
    position: absolute;
    align-self: flex-end;
    padding-bottom: 0px;
  }
  .portfolio-headline-subhead {
    max-width: 100%;
    padding-top: auto;
    padding-bottom: auto;
    justify-content: center;
  }
  .portfolio-workcard05 {
    width: 100%;
  }

  /* didnt get to save from teleport hq */
  .portfolio-header {
    height: auto;
  }
    /* didnt get to save from teleport hq */
}
@media(max-width: 479px) {
  .portfolio-video {
    left: -80%;
  }
  .portfolio-latestwork-section {
    padding-left: 0px;
    padding-right: 0px;
  }
  .portfolio-workcards {
    gap: 150px;
  }
  .portfolio-workcard-locked-aspect-ratio1 {
    animation-name: none;
    animation-delay: 0s;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .portfolio-homecontentimage-locked-aspect-ratio {
    animation-name: fadeInUp;
    animation-delay: 0s;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .portfolio-workcard-locked-aspect-ratio2 {
    width: 100%;
  }
  .portfolio-workcard-locked-aspect-ratio3 {
    width: 100%;
  }
  
  /* didnt get to save from teleport hq */
  .portfolio-headline-subhead {
    max-width: auto;
  }
/* didnt get to save from teleport hq */
}
