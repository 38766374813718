.header-title-container {
  width: 100%;
  height: 140px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.header-title-headertitle {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
}
.header-title-text {
  color: var(--dl-color-default-textdefault);
  width: 100%;
  height: auto;
  align-self: center;
  text-align: center;
  line-height: 46px;
}



@media(max-width: 991px) {
  .header-title-headertitle {
    height: 100%;
  }
  .header-title-text {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: static;
    align-self: center;
  }
}
