.portfolioproject1-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.portfolioproject1-portfolioproject1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-default-backgrounddark);
}
.portfolioproject1-portfolio1 {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 0 120px 100px;
  padding-left: auto;
  padding-right: auto;
  flex-direction: column;
  padding-bottom: 0 120px 100px;
}
.portfolioproject1-projectheader {
  gap: 40px;
  display: flex;
  padding: 60px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.portfolioproject1-headline-subhead {
  gap: 40px;
  width: auto;
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolioproject1-headline-subhead01 {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-text {
  color: var(--dl-color-default-textdefault);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 74px;
}
.portfolioproject1-text001 {
  font-weight: 300;
}
.portfolioproject1-text003 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 27px;
  text-decoration: none;
}
.portfolioproject1-stickerfinallayer1 {
  width: auto;
  height: auto;
  flex-grow: 1;
  align-self: stretch;
}
.portfolioproject1-skills-section {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 80px 140px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 80px 140px;
  justify-content: center;
}
.portfolioproject1-skills {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.portfolioproject1-skill {
  gap: 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.portfolioproject1-skill1 {
  gap: 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.portfolioproject1-imageplaceholder {
  width: 130px;
  height: 130px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: cover;
  background-image: url("/external/tq_rjtynyi9nr-sxj.png");
}
.portfolioproject1-headline-subhead02 {
  gap: 19px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-text013 {
  color: var(--dl-color-default-textdefault);
  width: 250px;
  height: auto;
  text-align: center;
  line-height: 32px;
}
.portfolioproject1-text015 {
  color: var(--dl-color-default-textgreyout1);
  width: 250px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolioproject1-skill2 {
  gap: 54px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.portfolioproject1-skill3 {
  gap: 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.portfolioproject1-imageplaceholder1 {
  width: 130px;
  height: 130px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: cover;
  background-image: url("/external/tq_sw5hkyuvun-jypu.png");
}
.portfolioproject1-headline-subhead03 {
  gap: 19px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-text017 {
  color: var(--dl-color-default-textdefault);
  width: 250px;
  height: auto;
  text-align: center;
  line-height: 32px;
}
.portfolioproject1-text018 {
  color: var(--dl-color-default-textgreyout1);
  width: 250px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolioproject1-skill4 {
  gap: 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.portfolioproject1-skill5 {
  gap: 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.portfolioproject1-imageplaceholder2 {
  width: 130px;
  height: 130px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: cover;
  background-image: url("/external/tq_emndblvagk-0lbc.png");
}
.portfolioproject1-headline-subhead04 {
  gap: 19px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-text021 {
  color: var(--dl-color-default-textdefault);
  width: 250px;
  height: auto;
  text-align: center;
  line-height: 32px;
}
.portfolioproject1-text022 {
  color: var(--dl-color-default-textgreyout1);
  width: 250px;
  height: auto;
  text-align: center;
  line-height: 27px;
}
.portfolioproject1-project-myrole {
  gap: 10px;
  display: flex;
  padding: 60px 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.portfolioproject1-header {
  gap: 28px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.portfolioproject1-headline-subhead05 {
  gap: 40px;
  width: 100%;
  display: flex;
  padding: 0 0 30px;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolioproject1-headline-subhead06 {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-text025 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  font-size: 48px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Epilogue;
  font-weight: 600;
  line-height: 68px;
  font-stretch: normal;
  text-decoration: none;
}
.portfolioproject1-graphtext {
  gap: 40px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolioproject1-text027 {
  color: var(--dl-color-default-textdefault);
  width: 100%;
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 27px;
}
.portfolioproject1-stickerfinallayer1-locked-aspect-ratio {
  width: 100%;
  height: 45vw;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  max-height: 700px;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-stickerfinallayer11 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  position: absolute;
  flex-grow: 1;
  align-self: stretch;
}
.portfolioproject1-frame {
  display: flex;
  z-index: 1;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolioproject1-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 60px;
}
.portfolioproject1-headertitle {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.portfolioproject1-text045 {
  color: var(--dl-color-default-textdefault);
  width: 100%;
  height: auto;
  padding: 40px;
  align-self: center;
  text-align: center;
  line-height: 46px;
}
.portfolioproject1-stickerfinallayer12 {
  width: 90vw;
  height: auto;
  flex-grow: 1;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}
.portfolioproject1-container2 {
  width: 100%;
  height: 140px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-headertitle1 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
}
.portfolioproject1-text048 {
  color: var(--dl-color-default-textdefault);
  width: 100%;
  height: auto;
  align-self: center;
  text-align: center;
  line-height: 46px;
}
.portfolioproject1-project-casestudies {
  gap: 10px;
  display: flex;
  padding: 80px 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.portfolioproject1-header1 {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.portfolioproject1-headline-subhead07 {
  gap: 40px;
  width: auto;
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolioproject1-headline-subhead08 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-titletop {
  width: 83px;
  height: 96px;
  display: flex;
  opacity: 0.55;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolioproject1-virtual-productiond-take-recorder {
  top: 0px;
  left: 0px;
  width: 83px;
  height: 83px;
  position: absolute;
}
.portfolioproject1-text051 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  align-self: stretch;
  font-style: Light;
  text-align: left;
  font-stretch: normal;
  text-decoration: none;
}
.portfolioproject1-text052 {
  font-weight: 300;
}
.portfolioproject1-text053 {
  color: var(--dl-color-default-textdefault);
  font-weight: 700;
  text-decoration: none;
}
.portfolioproject1-text054 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  font-size: 17px;
  align-self: stretch;
  text-align: left;
  font-family: "Epilogue";
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.portfolioproject1-button {
  width: 224px;
  height: 51px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 98, 80, 1);
}
.portfolioproject1-text065 {
  top: 5px;
  left: 28px;
  color: var(--dl-color-default-textdefault);
  height: auto;
  position: absolute;
  font-size: 20px;
  font-style: SemiBold;
  text-align: center;
  font-family: Epilogue;
  font-weight: 600;
  line-height: 42px;
  font-stretch: normal;
  text-decoration: none;
}
.portfolioproject1-stickerfinallayer13 {
  flex: 0;
  width: auto;
  height: auto;
  flex-grow: 1;
  max-width: 50%;
  align-self: stretch;
}
.portfolioproject1-project-casestudies1 {
  gap: 10px;
  display: flex;
  padding: 80px 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.portfolioproject1-header2 {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.portfolioproject1-stickerfinallayer14 {
  width: auto;
  height: auto;
  flex-grow: 1;
  max-width: auto;
  align-self: stretch;
}
.portfolioproject1-headline-subhead09 {
  gap: 40px;
  width: auto;
  display: flex;
  flex-grow: 1;
  max-width: auto;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolioproject1-headline-subhead10 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-titletop1 {
  width: 83px;
  height: 96px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolioproject1-virtual-productiond-virtual-camera-actor {
  top: 0px;
  left: 0px;
  width: 83px;
  height: 83px;
  opacity: 0.55;
  position: absolute;
}
.portfolioproject1-text067 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  align-self: stretch;
  font-style: Light;
  text-align: left;
  font-stretch: normal;
  text-decoration: none;
}
.portfolioproject1-text068 {
  font-weight: 300;
}
.portfolioproject1-text070 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  font-size: 17px;
  align-self: stretch;
  text-align: left;
  font-family: "Epilogue";
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.portfolioproject1-project-casestudies2 {
  gap: 10px;
  display: flex;
  padding: 80px 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.portfolioproject1-header3 {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.portfolioproject1-headline-subhead11 {
  gap: 40px;
  width: auto;
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolioproject1-headline-subhead12 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-titletop2 {
  width: 83px;
  height: 96px;
  display: flex;
  opacity: 0.50;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolioproject1-input-gamepad {
  top: 0px;
  left: 0px;
  width: 83px;
  height: 83px;
  position: absolute;
}
.portfolioproject1-text079 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  align-self: stretch;
  text-align: left;
}
.portfolioproject1-text081 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  font-size: 17px;
  align-self: stretch;
  text-align: left;
  font-family: "Epilogue";
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.portfolioproject1-stickerfinallayer15 {
  width: auto;
  height: auto;
  flex-grow: 1;
  max-width: 50%;
  align-self: stretch;
}
.portfolioproject1-project-casestudies3 {
  gap: 10px;
  display: flex;
  padding: 80px 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.portfolioproject1-header4 {
  gap: 28px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.portfolioproject1-frame3 {
  width: 50%;
  height: 42vw;
  display: flex;
  position: relative;
  max-height: 500px;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolioproject1-stickerfinallayer16 {
  top: 0px;
  left: 0px;
  width: 80%;
  height: 90%;
  position: absolute;
  flex-grow: 1;
}
.portfolioproject1-stickerfinallayer2 {
  top: auto;
  left: 0px;
  width: 100%;
  bottom: 0%;
  height: 60%;
  position: absolute;
  flex-grow: 1;
}
.portfolioproject1-headline-subhead13 {
  gap: 40px;
  width: auto;
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.portfolioproject1-headline-subhead14 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.portfolioproject1-titletop3 {
  width: 83px;
  height: 96px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.portfolioproject1-editor-u-id-sync-search {
  top: 0px;
  left: 0px;
  width: 83px;
  height: 83px;
  opacity: 0.55;
  position: absolute;
}
.portfolioproject1-text092 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 46px;
}
.portfolioproject1-text094 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  font-size: 17px;
  align-self: stretch;
  text-align: left;
  font-family: "Epilogue";
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .portfolioproject1-skills-section {
    align-self: center;
  }
  .portfolioproject1-skills {
    gap: var(--dl-space-space-fourunits);
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .portfolioproject1-headertitle {
    height: 100%;
  }
  .portfolioproject1-text045 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: static;
    align-self: center;
  }
  .portfolioproject1-text046 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: static;
    align-self: center;
  }
  .portfolioproject1-headertitle1 {
    height: 100%;
  }
  .portfolioproject1-text048 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: static;
    align-self: center;
  }
  .portfolioproject1-text049 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: static;
    align-self: center;
  }
}
@media(max-width: 767px) {
  .portfolioproject1-headline-subhead {
    width: auto;
    max-width: auto;
  }
}
@media(max-width: 479px) {
  .portfolioproject1-portfolio1 {
    max-width: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .portfolioproject1-skills {
    gap: var(--dl-space-space-fourunits);
  }
}
