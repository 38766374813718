.portfolio-aboutpage-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-backgrounddark);
}
.portfolio-aboutpage-portfolio-aboutpage {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  overflow-x: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-default-backgrounddark);
}
.portfolio-aboutpage-iframe {
  top: 0px;
  left: 40vw;
  width: 60vw;
  height: 45vw;
  margin: auto;
  z-index: 1;
  position: absolute;
}
.portfolio-aboutpage-aboutheader {
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  min-height: 75vh;
  align-items: center;
  padding-top: 140px !important;
  padding-left: auto;
  padding-right: auto;
  flex-direction: column;
  padding-bottom: 140px !important;
  justify-content: center;
}
.portfolio-aboutpage-text {
  color: rgb(243, 243, 243);
  width: auto;
  height: auto;
  z-index: 100;
  flex-grow: 1;
  font-size: 17px;
  align-self: flex-start;
  text-align: left;
  font-family: "Epilogue";
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.portfolio-aboutpage-text04 {
  color: var(--dl-color-default-textgreyout1);
  font-size: 17px;
  font-style: normal;
  font-family: "Epilogue";
  line-height: 27px;
}
.portfolio-aboutpage-text05 {
  color: var(--dl-color-default-textgreyout1);
  font-size: 17px;
  font-style: normal;
  font-family: "Epilogue";
  line-height: 27px;
}
.portfolio-aboutpage-text06 {
  color: var(--dl-color-default-textgreyout1);
  font-size: 17px;
  font-family: "Epilogue";
  font-weight: 700;
  line-height: 27px;
}
.portfolio-aboutpage-text07 {
  color: var(--dl-color-default-textgreyout1);
  font-size: 17px;
  font-style: normal;
  font-family: "Epilogue";
  font-weight: 700;
  line-height: 27px;
}
.portfolio-aboutpage-text08 {
  color: var(--dl-color-default-textgreyout1);
}
.portfolio-aboutpage-text09 {
  color: var(--dl-color-default-textgreyout1);
}
.portfolio-aboutpage-text10 {
  color: var(--dl-color-default-textgreyout1);
}
.portfolio-aboutpage-text11 {
  color: var(--dl-color-default-textgreyout1);
}
@media(max-width: 1200px) {
  .portfolio-aboutpage-iframe {
    width: 70vw;
  }
  .portfolio-aboutpage-aboutheader {
    position: relative;
    padding-top:140px;
  }
  .portfolio-aboutpage-text {
    z-index: 100;
  }
}
@media(max-width: 991px) {
  .portfolio-aboutpage-container {
    background-color: #000000;
  }
  .portfolio-aboutpage-iframe {
    width: 80vw;
    height: 70vw;
  }
}
@media(max-width: 767px) {
  .portfolio-aboutpage-iframe {
    top: 80px;
    left: 0px;
    right: 0px;
    width: 140vw;
    height: 75vw;
    margin: auto;
    position: absolute;
  }
  .portfolio-aboutpage-text {
    padding-top: 30vw;
  }
}
