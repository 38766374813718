.navbar-container {
  width: 100%;
  height: 84px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  z-index: 10;
}
.navbar-navigation {
  width: auto;
  height: 80px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  background-color: var(--dl-color-gray-black);
}
.navbar-navnavmenu {
  gap: 50px;
  top: 26px;
  right: var(--dl-space-space-oneandhalfunits);
  width: 436px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-end;
}
.navbar-text {
  color: var(--dl-color-default-textdefault);
  height: auto;
  text-align: left;
  line-height: 27px;
  text-decoration: none;
}
.navbar-text1 {
  color: var(--dl-color-default-textdefault);
  height: auto;
  text-align: left;
  line-height: 27px;
  text-decoration: none;
}
.navbar-line1 {
  width: 24px;
  height: var(--dl-size-size-xsmall);
  align-self: center;
}
.navbar-text3 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  font-size: 17px;
  font-style: Regular;
  text-align: left;
  font-family: Epilogue;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.navbar-text5 {
  color: var(--dl-color-default-textmute1);
  height: auto;
  font-size: 17px;
  font-style: Regular;
  text-align: left;
  font-family: Epilogue;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.navbar-frame427319348 {
  top: 27.15625px;
  left: 67px;
  width: 93px;
  display: flex;
  position: absolute;
  align-items: flex-start;
}
.navbar-text7 {
  color: var(--dl-color-default-textdefault);
  width: 93px;
  height: auto;
  text-align: left;
  line-height: 27px;
  text-decoration: none;
}






@media(max-width: 767px) {
  .navbar-navnavmenu {
    gap: 20px;
  }
  .navbar-line1 {
    display: none;
  }
  .navbar-frame427319348 {
    left: 24px;
  }
}
@media(max-width: 479px) {
  .navbar-text {
    display: none;
  }
  .navbar-text3 {
    display: none;
  }
}
